import React from 'react';
import firebase from '../../utils/firebase';
import {Row, Col, message} from 'antd';
import ima from '../../../assets/images/icono-pregunta.png';
import '../../../assets/css/style_demo.css';


class Enviar extends React.Component {

  state = {
    username: '',
    company: '',
    rol: '',
    step: 'register',
    question: '',
    nombre_curso:'demo_thethree'
  };
  
  render() {
    const { username, company, rol, step, question } = this.state;

    if(step === 'register') {
      return (
        <article>

          <div style={{ padding:'4% 7%' }}>
            
            <div style={{textAlign: 'left'}}>
            <Row>
              <Col span={17} offset={1}>
              <h5 style={{fontSize: '2em', color:'#ffffff', fontFamily:'Economica', fontWeight:'bold'}}>
              Realiza preguntas a <br></br>los ponentes.
              </h5>
              </Col>
              <Col span={6}>
                <img alt="preguntas" style={{width:'100%'}} src={ima}/>
              </Col>
            </Row>
              
              <form style={{marginBottom: '1em'}}>
                <div style={{ margin: '10px 0', fontSize: '16px'}}>
                  <label style={{fontFamily:'MyriadProregular', fontWeight:'bold'}} htmlFor="username">
                    <span>NOMBRE</span>
                  </label>
                  <br />
                  <input
                      style={{width:'100%', height:'30px', border:'1px solid #eb4b1c', color:'#000000'}}
                      id="username"
                      type="text"
                      value={username}
                      onChange={({ target }) => {
                        this.setState({
                          username: target.value
                        });
                      }}
                    />
                </div>
                <div style={{ margin: '15px 0', fontSize: '16px'}}>
                  <label style={{fontFamily:'MyriadProregular', fontWeight:'bold'}} htmlFor="company">
                    <span>EMPRESA</span>
                  </label>
                  <br />
                  <input
                      style={{width:'100%', height:'30px', border:'1px solid #eb4b1c', color:'#000000'}}
                      id="company"
                      type="text"
                      value={company}
                      onChange={({ target }) => {
                        this.setState({
                          company: target.value
                        });
                      }}
                    />
                </div>
                <div style={{ margin: '15px 0', fontSize: '16px'}}>
                  <label style={{fontFamily:'MyriadProregular', fontWeight:'bold'}} htmlFor="position">
                    <span>PUESTO EN LA EMPRESA</span>
                  </label>
                  <br />
                  <input
                      style={{width:'100%', height:'30px', border:'1px solid #eb4b1c', color:'#000000'}}
                      id="position"
                      type="text"
                      value={rol}
                      onChange={({ target }) => {
                        this.setState({
                          rol: target.value
                        });
                      }}
                    />
                </div>
                <button type="button" style={{
                  border: '2px solid #eb4b1c',
                  color: '#ffffff',
                  backgroundColor: '#eb4b1c',
                  borderRadius: '2px',
                  fontSize: 16,
                  fontFamily:'MyriadProregular', 
                  fontWeight:'bold'
                }} onClick={() => {

                  if(this.state.username === '' || this.state.username === ' '){
                    //alert('Ingresa tu nombre');
                    message.warning('Ingresa tu nombre');
                    return;
                  }

                  if(this.state.company === '' || this.state.company === ' '){
                    message.warning('Ingresa tu empresa');
                    return;
                  }

                  if(this.state.rol === '' || this.state.rol === ' '){
                    message.warning('Ingresa tu posición en la empresa');
                    return;
                  }

                  this.setState({
                    step: 'question'
                  })
                }}>
                  <span>CONTINUAR</span>
                </button>
              </form>
            </div>
          </div>
        </article>
      );
    }
    
    return (
      <article>
        <div style={{ padding:'4% 7%' }}>
          <div style={{textAlign: 'left'}}>
          <Row>
              <Col span={13} offset={1}>
              <h5 style={{fontSize: '1.8em', color:'#ffffff', fontFamily:'Economica', fontWeight:'bold'}}>
              Realiza preguntas a <br></br>los ponentes.
              </h5>
              </Col>
              <Col span={6}>
                <img alt="preguntas" style={{width:'100%'}} src={ima}/>
              </Col>
            </Row>
            <form style={{marginBottom: '1em'}}>
              <div style={{ fontSize: '18px'}}>
                <label htmlFor="username" style={{fontFamily:'MyriadProregular', fontWeight:'bold'}}>
                  <span>HAZ UNA PREGUNTA AL EXPOSITOR</span>
                </label>
                <br />
                <textarea
                    style={{width:'100%', border:'1px solid #eb4b1c', color:'#000000', rows:'10'}}
                    rows="6"
                    id="username"
                    type="text"
                    value={question}
                    onChange={({ target }) => {
                      this.setState({
                        question: target.value
                      });
                    }}
                  />
              </div>
              <button type="button" style={{
                  border: '2px solid #eb4b1c',
                  color: '#ffffff',
                  backgroundColor: '#eb4b1c',
                  borderRadius: '4px',
                  fontSize: 16,
                  fontFamily:'MyriadProregular', 
                  fontWeight:'bold'
                }} onClick={() => {

                  if(this.state.question == '' || this.state.question == ' '){
                    // alert('Escribe una pregunta');
                    message.error('Escribe una pregunta');
                    return;
                  }

                firebase.sendQuestion({
                  question: question,
                  name: username,
                  position: rol,
                  bussines: company,
                  nombre_curso:this.state.nombre_curso,
                  status: 'pendiente',

                },() => {

                  this.setState({
                    question:''
                  });

                 // alert('Pregunta enviada');
                  message.success('Pregunta enviada');
                });
              }}>
                <span>ENVIAR PREGUNTA</span>
              </button>
            </form>
          </div>
        </div>
      </article>
    );
  }
}



export default Enviar;