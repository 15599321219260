import React from 'react';
import firebase from '../../utils/firebase';
import {  Row, Col} from 'antd';
import ima from '../../../assets/images/icono_ponente.png';
import '../../../assets/css/style_demo.css';

const nombre_curso = 'demo_thethree';

class Visualizar extends React.Component {

  state = {
    questions: [],
  }

  componentDidMount(){
    firebase.questions((data)=>{
      this.setState({
        questions: data,
      })
    });
  }

  render(){
    let { questions } = this.state;

    questions = questions.filter((item, idx) => {
      if(item.nombre_curso == nombre_curso){
        return true;
      }
      return false;
    });

    return (<div>
      <div style={{width: '100%', padding:'4% 2%'}}>
      <Row>
              <Col span={12} offset={2}>
              <h5 style={{fontSize: '2em', color:'#000000', fontFamily:'Economica', fontWeight:'bold', textAlign:'left'}}>
              Preguntas <br/> para ponentes.
              </h5>
              </Col>
              <Col span={6}>
                <img alt="preguntas" style={{width:'100%'}} src={ima}/>
              </Col>
            </Row>
        {questions.map((item,idx)=>{
          return  item.status === 'aceptada' ? 
          (<div style={{backgroundColor:'#ffffff', padding:'0% 7%'}} key={item.key}>
            <p style={{textAlign:'left', lineHeight:'1.3', fontSize:'1em'}}>
              <span style={{fontFamily:'MyriadProBold'}}>{item.question}</span><br/>
              <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.name}</span><br/>
              <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.bussines}</span><br/>
            <hr style={{ border:'1px solid #eb4b1c'}} /></p>
            
          </div>) : null;
        })}
      </div>
      
    </div>)
  }
}


export default Visualizar;