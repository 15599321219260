import React from 'react';
import firebase from '../../utils/firebase';
import { Tabs , Row, Col, message} from 'antd';
import '../../../assets/css/style_demo.css';

const nombre_curso = "demo_thethree";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}
class Admin extends React.Component {

  state = {
    questions: [],
    tipo_preguntas: 'pendientes'
  }

  constructor(props){
    super(props);
    this.cambiarTipoPregunta = this.cambiarTipoPregunta.bind(this);
  }

  componentDidMount(){
    firebase.questions((data)=>{
      this.setState({
        questions: data,
      })
    });
  }

  aceptQuestion = (item) => {
    firebase.updateQuestion(item,'aceptada',(err)=>{
      if(err){
        //alert('Ha ocurrido un error');
        message.error('Ha ocurrido un error');
      }else{
        //alert('Pregunta actualizada');
        message.success('Pregunta actualizada');
      }
    })
  };

  rejectQuestion = (item) => {
    firebase.updateQuestion(item,'rechazada',(err)=>{
      if(err){
        message.error('Ha ocurrido un error');
      }else{
        message.success('Pregunta actualizada');
      }
    })
  };

  answerQuestion = (item) => {
    firebase.updateQuestion(item,'contestada',(err)=>{
      if(err){
        message.error('Ha ocurrido un error');
      }else{
        message.success('Pregunta actualizada');
      }
    })
  };

  retrieveQuestion = (item) => {
    firebase.updateQuestion(item,'pendiente',(err)=>{
      if(err){
        message.error('Ha ocurrido un error');
      }else{
        message.success('Pregunta actualizada');
      }
    })
  };

  cambiarTipoPregunta = (tipo_pregunta) =>{
    this.setState({tipo_preguntas:tipo_pregunta})
  };


  render(){
    let { questions } = this.state;

    questions = questions.filter((item, idx) => {
      if(item.nombre_curso == nombre_curso){
        return item;
      }
    });
    console.log();
    console.log(questions);

    return (<div>
      <div style={{padding:'4% 3%'}}>
        <Row>
          <Col span={24}>
            <h5 style={{fontSize: '2em', color:'#ffffff', fontFamily:'Economica', fontWeight:'bold'}}>
              Preguntas
            </h5>
          </Col>
        </Row>
      <Tabs defaultActiveKey="1" onChange={callback} centered>
        <TabPane tab="Pendientes" key="1" style={{fontFamily:'MyriadProBold', fontWeight:'bold', fontSize:'1em'}}>
        <div style={{flex: 1}}>
        {questions.map((item,idx)=>{
          return  item.status === 'pendiente' ? (
          <div  key={item.key}>
            <Row>
              <Col span={16} style={{textAlign:'left'}}>
              <p style={{color:'#ffffff', lineHeight:'1.3', fontSize:'1.2em' }}>
                <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.name} - {item.position}</span><br />
                <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.bussines}</span><br/>
                <span style={{fontFamily:'MyriadProBold'}}>{item.question}</span></p>
              </Col>
              <Col span={8}>
                <button className='botonP' onClick={()=>{
                  this.aceptQuestion(item);
                }}>ACEPTAR</button>
                <br/>
                <button className='botonP' onClick={()=>{
                  this.rejectQuestion(item);
                }}>RECHAZAR</button>
            
              </Col>
              <Col span={24}>
                <hr style={{ border:'1px solid #ffffff'}} />
                </Col>
            </Row>
            
          </div>) : null;
        })}
      </div>
        </TabPane>
        <TabPane tab="Aceptadas" key="2">
        <div style={{flex: 1}}>
        {questions.map((item,idx)=>{
          return  item.status === 'aceptada' ? (
          <div key={item.key}>
            <Row align="bottom">
              <Col span={16} style={{textAlign:'left'}}>
            <p style={{color:'#ffffff', lineHeight:'1.3', fontSize:'1.2em'}}>
              <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.name} - {item.position}</span> <br />
              <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.bussines}</span><br/>
              <span style={{fontFamily:'MyriadProBold'}}>{item.question}</span></p>
            </Col>
            <Col span={8}>
            <button className='botonP' onClick={()=>{
              this.answerQuestion(item);
            }}>CONTESTADA</button>
            </Col>
            <Col span={24}>
                <hr style={{ border:'1px solid #ffffff'}} />
                </Col>
            </Row>
          </div>) : null;
        })}
      </div>
        </TabPane>
        <TabPane tab="Rechazadas" key="3">
        <div style={{flex: 1}}>
        {questions.map((item,idx)=>{
          return  item.status === 'rechazada' ? (
          <div key={item.key}>
            <Row align="bottom">
              <Col span={16} style={{textAlign:'left'}}>
            <p style={{color:'#ffffff', lineHeight:'1.3', fontSize:'1.2em'}}>
              <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.name} - {item.position}</span> <br /> 
              <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.bussines}</span><br/>
              <span style={{fontFamily:'MyriadProBold'}}>{item.question}</span></p>
            </Col>
            <Col span={8} >
            <button className='botonP' onClick={()=>{
              this.retrieveQuestion(item);
            }}>RECUPERAR</button>
            </Col>
            <Col span={24}>
                <hr style={{ border:'1px solid #ffffff'}} />
                </Col>
            </Row>
          </div>) : null;
        })}
      </div>
        </TabPane>
        <TabPane tab="Contestadas" key="4">
        <div style={{flex: 1}}>
        {questions.map((item,idx)=>{
          return  item.status === 'contestada' ? (
          <div key={item.key}>
            <Row>
              <Col span={24} style={{textAlign:'left'}}>
            <p style={{color:'#ffffff', lineHeight:'1.3', fontSize:'1.2em'}}>
            <span style={{fontFamily:'MyriadProSemB', fontStyle:'italic'}}>{item.name} - {item.position}</span> <br /> 
            <span style={{fontFamily:'MyriadProLight', fontStyle:'italic'}}>{item.bussines}</span><br/>
            <span style={{fontFamily:'MyriadProBold'}}>{item.question}</span><br/>
            <hr style={{ border:'1px solid #ffffff'}} /></p>
            </Col>
            </Row>
          </div>) : null;
        })}
      </div>
        </TabPane>
      </Tabs>
      
      </div>
      
    </div>)
  }
}


export default Admin;