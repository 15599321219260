import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Demo} from './componentes/paginas/demo/Demo';
import 'antd/dist/antd.css';

function App() {
  return (
    <div className="App">dffgerg
      <Router>
        <Route path="/demo" component={Demo} />
      </Router>
    </div>
  );
}

export default App;
