import React from 'react';
import { Layout, Row, Col} from 'antd';
import ima from '../../../assets/images/DEMO_impact_admin_header.jpg';
import Enviar from './Enviar';
import Admin from './Admin';
import Visualizar from './Visualizar';

const { Content} = Layout;
export const Demo = () => { 
  return(
    <div style={{backgroundColor:'#242323', height:'700px'}}>
      <Layout>
      <Content>
        <Row align="center">
          <Col span={24}><img alt="header" style={{width:'100%'}} src={ima}></img></Col>
        </Row>

        <Row justify="left">
          <Col xs={{span:24}} sm={{span:24}} md={{span:7}} xl={{span:7}} style={{backgroundColor:'#242323', color:'white'}}>
            <Enviar />
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:10}} xl={10} style={{backgroundColor:'#242323', color:'white'}}>
        <Admin />
      </Col>
      <Col xs={{span:24}} sm={{span:24}} md={{span:7}} xl={7} style={{backgroundColor:'white', color:'black'}}>
        <Visualizar />
      </Col>
    </Row>
      </Content>
    </Layout>
    </div>
  ); 
}