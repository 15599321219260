const firebase = require("firebase");


//Antp
// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyBIHgA-NRVDKnYL_SDzbjiVfKkYTffKkUA",
  authDomain: "preguntas-thethree.firebaseapp.com",
  databaseURL: "https://preguntas-thethree.firebaseio.com",
  projectId: "preguntas-thethree",
  storageBucket: "preguntas-thethree.appspot.com",
  messagingSenderId: "168547951063",
  appId: "1:168547951063:web:eaf9015e1cb330d4faab92"
};


class Firebase {
  
  constructor(){
    this.db = firebase.initializeApp(firebaseConfig).database();
  }

  collectionToArr = (collection) => {
    var rows = [];
    
    if( collection && collection.val() ){        

        collection.forEach((obj)=>{
        let item = obj.val();
        item.key = obj.key;
        rows.push(item);
      });
    }

    return rows;
  };

  sendQuestion = (toSent ,cb) => {
    this.db.ref('questions')
      .push(toSent,(data)=>{
        cb(data);
      })
      .catch((err)=>{
        cb(null, err);
      });
  }

  survey = (cb) => {
    this.db.ref('surveys')
      .orderByChild('active')
      .equalTo(true)
      .once("value",(data)=>{
        cb( this.collectionToArr(data)[0] );
      });
  }

  addListener = (url, cb) => {
    const auxRef = this.db.ref(url);

    return auxRef.on("child_added",cb);
    
  }

  notifications = (cb) => {
    this.db.ref('notifications')
      .once("value",(data)=>{
        cb( this.collectionToArr(data) );
      });
  }

  savetoken = (token,cb) => {
    this.db.ref('tokens')
    .push({
      expo_push_token: token,
    },(data)=>{
      cb(data);
    })
    .catch((err)=>{
      cb(null, err);
    });
  }

  regions = (cb) => {
    this.db.ref('regions')
      .once("value",(data)=>{
        cb( this.collectionToArr(data) );
      });
  }

  regionsNotifications = (regionIdentifier,cb) => {
    this.db.ref('regions_notifications')
      .orderByChild('region_identifier')
      .equalTo(regionIdentifier)
      .once("value",(data)=>{
        cb( this.collectionToArr(data) );
      });
  }

  questions = (cb) => {
    this.db.ref('questions')
      /*.orderByChild('status')
      .equalTo('pendiente')*/
      .on("value",(data)=>{
        cb( this.collectionToArr(data) );
      });
  }

  updateQuestion = (item,newStatus,cb) => {
    let updates = { };
    const url = `/${item.key}`;
    updates[url] = {
      bussines: item.bussines,
      name: item.name,
      position: item.position,
      question: item.question,
      status: newStatus,
      nombre_curso: item.nombre_curso
    };
    this.db.ref('questions').update(updates,cb);

  }
  
}

export default Firebase = new Firebase();
